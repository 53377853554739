import * as React from 'react';
import EnquireForm from '@webSections/EnquireForm';
import Base from '@webLayouts/Base/Base';

const PageEnquire: React.FC = () => {
  return (
    <Base theme="light-home">
      <EnquireForm />
    </Base>
  );
};

export default PageEnquire;
